import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/components/docs-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "create-your-first-source",
      "style": {
        "position": "relative"
      }
    }}>{`Create Your First Source`}<a parentName="h2" {...{
        "href": "#create-your-first-source",
        "aria-label": "create your first source permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p><strong parentName="p">{`To create your first Source:`}</strong></p>
    <ol>
      <li parentName="ol">{`Navigate to the Data & Analytics Application`}</li>
      <li parentName="ol">{`Select Sources from the navigation menu`}</li>
      <li parentName="ol">{`Click the (+) icon in the bottom corner`}</li>
      <li parentName="ol">{`Select "Javascript" as the type of Source you'd like to configure.`}</li>
      <li parentName="ol">{`Provide a name for the Source`}</li>
      <li parentName="ol">{`Click "Create Source"`}</li>
      <li parentName="ol">{`Click on the Source name in the list of Sources, then select the "Setup" tab`}</li>
    </ol>
    <h2 {...{
      "id": "install-the-firstparty-snippet-on-your-website",
      "style": {
        "position": "relative"
      }
    }}>{`Install the Firstparty snippet on your website`}<a parentName="h2" {...{
        "href": "#install-the-firstparty-snippet-on-your-website",
        "aria-label": "install the firstparty snippet on your website permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p>{`Copy the entire block of code provided on the Source setup page and paste it at the top of the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<head>`}</code>{` tag on every
page of your website. Your snippet will look similar to this snippet below:`}</p>
    <div className="alert alert-info">This is an example - make sure to use the Javascript specified by your Source</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`script `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
    `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`!`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`var`}</span>{` r `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` window`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`firstparty `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` window`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`firstparty `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`||`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`!`}</span>{`r`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`initialize`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
            `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`r`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`invoked`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`void`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`window`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`console `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&&`}</span>{` console`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`error `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&&`}</span>{` console`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`error`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"Firstparty snippet included twice."`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
            r`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`invoked `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`!`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` 
            r`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`methods `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"trackSubmit"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` 
            `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"trackClick"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` 
            `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"trackLink"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` 
            `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"trackForm"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` 
            `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"pageview"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` 
            `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"identify"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` 
            `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"reset"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` 
            `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"group"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` 
            `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"track"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` 
            `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"ready"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` 
            `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"alias"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` 
            `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"debug"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` 
            `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"page"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` 
            `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"once"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` 
            `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"off"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` 
            `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"on"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` 
            `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"addSourceMiddleware"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` 
            `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"addIntegrationMiddleware"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` 
            `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"setAnonymousId"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` 
            `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"addDestinationMiddleware"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` 
            r`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`factory`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`t`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
                `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
                    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`var`}</span>{` e `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Array`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`prototype`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`slice`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`call`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`arguments`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
                    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` e`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`unshift`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`t`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` r`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`push`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`e`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` r
                `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
            `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
            `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`for`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`var`}</span>{` t `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` t `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{` r`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`methods`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`length`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` t`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`++`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
                `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`var`}</span>{` e `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` r`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`methods`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`t`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
                r`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`e`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` r`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`factory`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`e`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
            `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
            r`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`load`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`t`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` e`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` i`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
                r`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`_writeKey `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` t`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` r`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`_host `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` e`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` r`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`_firstpartyOptions `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` i`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
                `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`var`}</span>{` a `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"/js/firstparty.min.js"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
                `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`void`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`!==`}</span>{` i `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&&`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`void`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`!==`}</span>{` i`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`libraryPath `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&&`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`a `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` i`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`libraryPath`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
                `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`var`}</span>{` o `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` document`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`createElement`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"script"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
                o`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`type `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"text/javascript"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` o`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`async `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`!`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` o`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`src `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"//"`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` e `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` a`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
                `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`var`}</span>{` n `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` document`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`getElementsByTagName`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"script"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
                n`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`parentNode`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`insertBefore`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`o`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` n`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
            `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` r`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`SNIPPET_VERSION`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"0.1.0"`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
firstparty`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`load`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'KEWr7ZV4nZPSKoAX'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'https://fp.yourwebsite.com'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
firstparty`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`page`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`script`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span></code></pre></div>
    <p>{`After you've installed the Javascript snippet on your website, you'll see the Source setup page update and confirm
that data is being collected. Congrats on installing Firstparty!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      